import { URL_PREFIX } from 'utils/constant'
import { downLoadFile, get, post } from '../xhr/fetch'

/**
 * 修改客户密码
 * @param data
 * @returns
 */
export const MODIFY_CUSTOMER_PASSWORD = (data: any) => {
  return post(`/${URL_PREFIX}/pdt-store/modify-pwd `, data)
}

/**
 * 修改管理端密码
 * @param data
 * @returns
 */
export const MODIFY_ADMIN_PASSWORD = (data: any) => {
  return post(`/${URL_PREFIX}/console/modify-pwd `, data)
}

/**
 * 获取用户信息
 * @param data
 * @returns
 */
export const GET_USER_INFO = () => {
  return get(`/${URL_PREFIX}/console/user-info`)
}

/**
 * 获取客户用户信息
 * @param data
 * @returns
 */
export const GET_CUSTOMER_INFO = () => {
  return post(`/${URL_PREFIX}/pdt-store/personal-info`)
}

/**
 * 下载oss文件
 * @param storageKey 文件key
 * @returns
 */
export const DOWNLOAD_FILE = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/aliyun-oss/file-download`, data, 'post')
}

/**
 * 保费统计
 * @returns
 */
export const GET_PREMIUM_STAT = (data: any) => {
  return get(`/${URL_PREFIX}/insure/premium-stat`, data)
}

/**
 *  我的应收
 * @returns
 */
export const GET_MY_RECEIVE_STAT = (data: any) => {
  return get(`/${URL_PREFIX}/insure/my-receivable`, data)
}
