import axios, { AxiosResponse } from 'axios'
import { message } from 'antd'
import NProgress from 'nprogress'
import beforeLogout from 'utils/BeforeLogout'
import { getToken } from 'models/Global.model'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // common 的 base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    NProgress.start()
    let token = getToken()
    if (token) {
      config.headers['Authtoken'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    NProgress.done()
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    NProgress.done()
    const { data, config, headers, status } = response

    // 下载文件流
    if (config.responseType === 'blob') {
      if (data.type !== 'application/json') {
        return { blob: data, headers, type: data.type }
      } else if (data.type === 'application/json') {
        return new Promise((resolve) => {
          let reader = new FileReader()
          reader.onload = (e: any) => {
            if (e.target?.readyState === 2) {
              resolve(e.target?.result)
            }
          }
          reader.readAsText(data)
        })
      }
    }

    const res = response.data
    const { code } = res
    if (
      Number(code) === 0 ||
      Number(code) === 200 ||
      (Number(status) === 200 && !code) // 没有返回code，但是status为200
    ) {
      return response.data
    } else if (res.code === 403) {
      // 无权限
      message.error(res.msg || res.message)
      return
    } else if (res.code === 401) {
      message.error(res.msg || res.message)
      // 登录过期
      beforeLogout()
      return
    } else if (res.code === 404) {
      message.error('接口未找到')
    } else {
      message.error(res.msg || res.message || '请求错误')
      return Promise.reject(res)
    }
  },
  (error) => {
    NProgress.done()
    console.log('eeeee', error)
    if (error.response) {
      let obj = error.response
      if (obj.config.url.indexOf('/check') > -1) {
        return obj.data
      }
      if (obj.status > 400 && obj.status < 404) {
        message.warning('您的登录已过期，请重新登录')
        beforeLogout()
      } else if (obj.status === 404) {
        message.error('接口未找到')
      } else {
        message.error(
          obj.message ||
            obj?.data?.message ||
            obj.data.errorMessage ||
            '服务异常,请稍后再试'
        )
      }
      return Promise.reject({
        code: obj.status,
        message:
          obj.message ||
          obj?.data?.message ||
          obj.data.errorMessage ||
          '服务异常,请稍后再试',
        data: obj.data
      })
    } else {
      message.error('请求超时')
      return Promise.reject(error)
    }
  }
)

const get = (url: string, params = {}) => {
  return service({
    url: url,
    method: 'get',
    params
  }).then((rs) => {
    return rs
  })
}

const post = (url: string, data = {}) => {
  return service({
    url: url,
    method: 'post',
    data
  }).then(
    (rs) => {
      return rs
    },
    (rj) => {
      return rj
    }
  )
}

const postQuery = (url: string, params = {}) => {
  return service({
    url: url,
    method: 'post',
    params
  }).then(
    (rs) => {
      return rs
    },
    (rj) => {
      return rj
    }
  )
}

const put = (url: string, params = {}) => {
  return service({
    url: url,
    method: 'put',
    params
  }).then(
    (rs) => {
      return rs
    },
    (rj) => {
      return rj
    }
  )
}
const _put = (url: string, data = {}) => {
  return service({
    url: url,
    method: 'put',
    data
  }).then(
    (rs) => {
      return rs
    },
    (rj) => {
      return rj
    }
  )
}

const del = (url: string, data = {}) => {
  return service({
    url: url,
    method: 'delete',
    data
  }).then((rs) => {
    return rs
  })
}

const _del = (url: string, params = {}) => {
  return service({
    url: url,
    method: 'delete',
    params
  }).then((rs) => {
    return rs
  })
}

const patch = (url: string, data?: {}, method = 'patch') => {
  return service({
    url: url,
    method,
    data
  }).then((rs) => {
    return rs
  })
}

const postData = (url: string, data?: {}) => {
  return patch(url, data, 'post')
}

const postFormData = (url: string, data?: {}) => {
  return service({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  }).then((rs) => {
    return rs
  })
}

const downLoadFile = (url: string, data: object, method = 'get') => {
  return axios({
    url: url,
    method: method,
    params: method === 'get' && data,
    data: method === 'post' && data,
    headers: {
      authToken: `Bearer ${getToken()}`,
      'content-type': 'application/json; charset=utf-8'
    },
    responseType: 'blob'
    // processData: false, // 告诉axios不要去处理发送的数据(重要参数)
    // contentType: false // 告诉axios不要去设置Content-Type请求头
  })
    .then((res) => {
      const { data } = res
      // 错误处理
      if (data.type === 'application/json') {
        let reader = new FileReader()
        reader.addEventListener('loadend', () => {
          const { msg } = JSON.parse(reader.result as string)
          message.error(msg || '下载文件失败')
        })
        reader.readAsText(data, 'utf-8')
      } else {
        // application/pdf
        return res.data
      }
    })
    .catch((error) => {
      if (error.response.data.status === 500) {
        message.error('系统异常')
        return Promise.reject(error)
      } else {
        return error.response.data
      }
    })
}

// 以上方法用于临时联调履职用--开始
export {
  get,
  post,
  put,
  del,
  _del,
  patch,
  _put,
  service,
  downLoadFile,
  postData,
  postQuery,
  postFormData
}
