import { makeAutoObservable, runInAction } from 'mobx'
import * as API from 'service/api/GlobalApi'

class GlobalStore {
  constructor() {
    makeAutoObservable(this)
  }

  modifyCustomerPassword = async (data: any) => {
    return await API.MODIFY_CUSTOMER_PASSWORD(data)
  }

  modifyAdminPassword = async (data: any) => {
    return await API.MODIFY_ADMIN_PASSWORD(data)
  }

  downloadFileByKey = async (data: any) => {
    return await API.DOWNLOAD_FILE(data)
  }

  getCustomerInfo = async () => {
    return await API.GET_CUSTOMER_INFO()
  }

  getPremiumStat = async (data: any) => {
    return await API.GET_PREMIUM_STAT(data)
  }

  getMyReceiveStat = async (data: any) => {
    return await API.GET_MY_RECEIVE_STAT(data)
  }
}

export default new GlobalStore()
