import { makeAutoObservable, runInAction } from 'mobx'
import * as API from 'service/api/SysManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'

class SysManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  userTable = new table(API.GET_USER_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.userTable.init(param, isPageNum, isResetParam)
    this.userTable.getList(pageNo, pageSize)
  }

  addUser = async (data: any) => {
    return await API.ADD_USER(data)
  }

  updateUser = async (data: any) => {
    return await API.UPDATE_USER(data)
  }

  delUser = async (data: any) => {
    return await API.DEL_USER(data)
  }

  disableUser = async (data: any) => {
    return await API.DISABLE_USER(data)
  }

  enableUser = async (data: any) => {
    return await API.ENABLE_USER(data)
  }

  resetPassword = async (data: any) => {
    return await API.RESET_PASSWORD(data)
  }

  roleTable = new table(API.GET_ROLE_LIST)

  initRole = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.roleTable.init(param, isPageNum, isResetParam)
    this.roleTable.getList(pageNo, pageSize)
  }

  addRole = async (data: any) => {
    return await API.ADD_ROLE(data)
  }

  updateRole = async (data: any) => {
    return await API.UPDATE_ROLE(data)
  }

  delRole = async (data: any) => {
    return await API.DEL_ROLE(data)
  }

  disableRole = async (data: any) => {
    return await API.DISABLE_ROLE(data)
  }

  enableRole = async (data: any) => {
    return await API.ENABLE_ROLE(data)
  }

  roleRelateUserList = []
  getRoleRelateUserList = async (data: any) => {
    let res = await API.GET_ROLE_USER_LIST(data)
    runInAction(() => {
      if (res.code === 200) {
        this.roleRelateUserList = res.data || []
      } else {
        this.roleRelateUserList = []
      }
    })
  }

  relateRoleUserList = async (data: any) => {
    return await API.RELATE_ROLE_USER_LIST(data)
  }

  getRoleMenuList = async (data: any) => {
    return await API.GET_ROLE_MENU_LIST(data)
  }

  authRoleMenuList = async (data: any) => {
    return await API.AUTH_ROLE_MENU_LIST(data)
  }

  // 菜单接口
  menuTable = new table(API.GET_MENU_LIST)

  initMenu = (data: IInit) => {
    const {
      param = {},
      pageSize = 20,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.menuTable.init(param, isPageNum, isResetParam)
    this.menuTable.getList(pageNo, pageSize)
  }

  addMenu = async (data: any) => {
    return await API.ADD_MENU(data)
  }

  updateMenu = async (data: any) => {
    return await API.UPDATE_MENU(data)
  }

  delMenu = async (data: any) => {
    return await API.DEL_MENU(data)
  }
}

export default new SysManageStore()
