import { makeAutoObservable } from 'mobx'
export default class table {
  constructor(api) {
    makeAutoObservable(this)

    this.getListApi = api
  }
  isPageNum = false
  isResetParam = false
  list
  loading = false
  params = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    size: 'default',
    showQuickJumper: true,
    showSizeChanger: true,
    onChange: (page, pageSize) => {
      const pagination = {
        current: page,
        pageSize: pageSize
      }
      this.onChange(pagination)
    },
    showTotal: (total) => `共 ${total} 条`
  }

  getList = (page, pageSize) => {
    this.setLoading(true)
    if (this.isResetParam) {
      this.pagination.current = page || this.pagination.current
      this.pagination.pageSize = pageSize || this.pagination.pageSize
    }
    let params = Object.assign({}, this.params, {
      pageSize: this.pagination.pageSize
    })
    if (this.isPageNum) {
      params.pageNum = this.pagination.current
    } else {
      params.pageNo = this.pagination.current
    }
    return this.getListApi(params)
      .then((rs) => {
        this.setLoading()
        if (
          (rs.code && Number(rs.code) === 200) ||
          rs.code === 0 ||
          Number(rs.code) === 0
        ) {
          this.updateList(
            rs.data || rs.result || rs.data?.data || rs.data?.rows
          )
          return rs
        } else {
          this.updateList([], 'error')
        }
      })
      .catch(() => {
        this.setLoading()
      })
  }

  setLoading = (value) => {
    this.loading = value ? true : false
  }

  onChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      if (sorter.order === 'ascend') {
        this.onParamsChange('sort', 0)
      } else {
        this.onParamsChange('sort', 1)
      }
    }
    this.pagination = Object.assign({}, this.pagination, {
      current: pagination.current,
      pageSize: pagination.pageSize
    })
    return this.getList(pagination.current, pagination.pageSize)
  }

  onListReset = () => {
    this.list = undefined
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
      size: 'small',
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (total) => `共 ${total} 条`,
      onChange: (page, pageSize) => {
        const pagination = {
          current: page,
          pageSize: pageSize
        }
        this.onChange(pagination)
      }
    }
  }

  updateList = (rs = {}, code = '') => {
    if (Array.isArray(rs)) {
      this.list =
        rs.map((item, index) => {
          item.rowKey = index + 1
          return item
        }) || []
    } else {
      let { data, records, rows } = rs
      data = data || records || rows || []
      this.list =
        data.map((item, index) => {
          item.rowKey = item.id || index + 1
          return item
        }) || []
    }
    this.pagination = Object.assign({}, this.pagination, {
      total: Number(rs.total || rs.totalCount) || 0
    })
    if (code === 'error') {
      // message.error('获取数据失败')
      this.pagination.total = 0
      this.pagination.current = 1
    }
  }

  operateFun = (fun, params) => {
    return fun(params).then((rs) => {
      return rs.success
    })
  }

  init = (params = {}, isPageNum = false, isResetParam = false) => {
    this.isPageNum = isPageNum
    this.isResetParam = isResetParam
    isResetParam && this.onParamsReset()
    for (let i in params) {
      let value = params[i]
      this.params[i] = value
    }
  }

  onParamsChange = (params = {}) => {
    this.params = Object.assign({}, this.params, params)
  }

  onParamsReset = (pageSize = 10) => {
    this.params = {}
    this.pagination.current = 1
    this.pagination.pageSize = pageSize
  }

  onResetParams = (param) => {
    this.params = { ...param }
  }
}
