import { makeAutoObservable, runInAction } from 'mobx'
import * as API from 'service/api/RiskManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'
import { IExtraRisk, IMainRisk } from 'models/RiskManage.model'

class RiskManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  mainRiskTable = new table(API.GET_MAIN_RISK_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.mainRiskTable.init(param, isPageNum, isResetParam)
    this.mainRiskTable.getList(pageNo, pageSize)
  }

  addMainRisk = async (data: any) => {
    return await API.ADD_MAIN_RISK(data)
  }

  getMainRiskInfo = async (data: any) => {
    return await API.GET_MAIN_RISK_INFO(data)
  }

  updateMainRisk = async (data: any) => {
    return await API.UPDATE_MAIN_RISK(data)
  }

  delMainRisk = async (data: any) => {
    return await API.DEL_MAIN_RISK(data)
  }

  extraRiskTable = new table(API.GET_EXTRA_RISK_PAGE_LIST)

  initExtraRisk = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.extraRiskTable.init(param, isPageNum, isResetParam)
    this.extraRiskTable.getList(pageNo, pageSize)
  }

  addExtraRisk = async (data: any) => {
    return await API.ADD_EXTRA_RISK(data)
  }

  updateExtraRisk = async (data: any) => {
    return await API.UPDATE_EXTRA_RISK(data)
  }

  delExtraRisk = async (data: any) => {
    return await API.DEL_EXTRA_RISK(data)
  }

  extraRiskList: IExtraRisk[] = []
  getExtraRiskList = async (data?: any) => {
    let res = await API.GET_EXTRA_RISK_LIST(data)
    runInAction(() => {
      if (res.code === 200) {
        this.extraRiskList = res.data || []
      } else {
        this.extraRiskList = []
      }
    })
  }
}

export default new RiskManageStore()
