import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { ConfigProvider } from 'antd'
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/es/locale/zh_CN'
import routes, { IRoute } from './router'
import stores from 'store'

const App = () => {
  return (
    <Provider {...stores}>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: 'rgb(20, 195, 156)'
          }
        }}>
        <Routes>
          {routes.map((route: IRoute) => (
            <Route path={route.path} element={route.element} key={route.key} />
          ))}
        </Routes>
      </ConfigProvider>
    </Provider>
  )
}

export default App
