import { makeAutoObservable, runInAction } from 'mobx'
import { message } from 'antd'
import * as API from 'service/api/LoginApi'
import { GET_USER_INFO } from 'service/api/GlobalApi'
import { beforeLogout } from 'utils/BeforeLogout'
import {
  ADMIN_PWD_KEY,
  ADMIN_REMEMBER_KEY,
  ADMIN_USER_KEY,
  CLIENT_TYPE_KEY,
  PWD_KEY,
  REMEMBER_KEY,
  TOKEN_KEY,
  USER_INFO_KEY,
  USER_KEY,
  USER_MENU_LIST_KEY
} from 'utils/constant'
import { decryptLogin, encodeLogin, encode } from 'utils/encode'
import { CLIENT_TYPE } from 'models/Global.model'

class LoginStore {
  constructor() {
    makeAutoObservable(this)

    let href = location.href
    let index = href.lastIndexOf('/')
    href = href.substring(index)
    let isCustom = !href.includes('adminLogin')
    this.isCustom = isCustom

    let rememberKey = isCustom ? REMEMBER_KEY : ADMIN_REMEMBER_KEY
    let rememberPwd = localStorage.getItem(rememberKey)
    if (rememberPwd) {
      this.rememberPwd = true
      let userKey = isCustom ? USER_KEY : ADMIN_USER_KEY
      let pwdKey = isCustom ? PWD_KEY : ADMIN_PWD_KEY
      let userName = localStorage.getItem(userKey)
      if (userName) {
        this.userName = userName
      }

      let pwd = localStorage.getItem(pwdKey)
      if (pwd) {
        this.passwd = decryptLogin(pwd) || ''
      }
    }
  }

  userInfo = {}
  loading: boolean = false
  rememberPwd: boolean = false
  userName: string = ''
  passwd: string = ''
  isCustom: boolean = false

  toggleRememberPwd = (rememberPwd = false) => {
    this.rememberPwd = rememberPwd
    let rememberKey = this.isCustom ? REMEMBER_KEY : ADMIN_REMEMBER_KEY
    if (rememberPwd) {
      localStorage.setItem(rememberKey, '1')
    } else {
      localStorage.removeItem(rememberKey)
    }
  }

  login = async (values: any, cb: Function, clientType: CLIENT_TYPE) => {
    runInAction(() => {
      this.loading = true
    })
    let { password, username } = values
    let decryptPwd = encode(password)
    let isCustom = clientType === CLIENT_TYPE.CUSTOM
    let actionApi = isCustom ? API.LOGIN : API.ADMIN_LOGIN
    actionApi({ username, password: decryptPwd })
      .then(async (rs: any) => {
        if (rs.code === 200) {
          let { token, payload } = rs.data ?? {}
          this.loginSuccess(clientType, token, payload, password, username)
          !isCustom && (await this.getUserMenus())
          cb?.()
        }
        runInAction(() => {
          this.loading = false
        })
      })
      .catch((err: any) => {
        message.error(err.message)
      })
  }

  clientType: CLIENT_TYPE | undefined
  adminLogin = async (values: any, cb: Function) => {
    runInAction(() => {
      this.loading = true
    })
    let { password, username } = values
    password = encode(password)
    API.ADMIN_LOGIN({ username, password })
      .then(async (rs: any) => {
        if (rs.code === 200) {
          let { token, payload } = rs.data ?? {}
          this.loginSuccess(
            CLIENT_TYPE.ADMIN,
            token,
            payload,
            username,
            password
          )
          await this.getUserMenus()
          cb?.()
        }
        runInAction(() => {
          this.loading = false
        })
      })
      .catch((err: any) => {
        message.error(err.message)
      })
  }

  loginSuccess = (
    clientType: CLIENT_TYPE,
    token: string,
    payload: any,
    passwd: string,
    userName: string
  ) => {
    let userKey = this.isCustom ? USER_KEY : ADMIN_USER_KEY
    let pwdKey = this.isCustom ? PWD_KEY : ADMIN_PWD_KEY
    if (this.rememberPwd) {
      localStorage.setItem(userKey, userName)
      localStorage.setItem(pwdKey, encodeLogin(passwd) || '')
    } else {
      localStorage.removeItem(userKey)
      localStorage.removeItem(pwdKey)
    }

    localStorage.setItem(USER_INFO_KEY, JSON.stringify(payload))
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(CLIENT_TYPE_KEY, clientType)
    this.clientType = clientType
  }

  logout = () => {
    beforeLogout()
  }

  getUserInfo = async () => {
    let res = await GET_USER_INFO()
    if (res.code === 200) {
      localStorage.setItem(USER_INFO_KEY, JSON.stringify(res.data))
    }
  }

  authMenuList = []
  getUserMenus = async () => {
    runInAction(() => {
      this.loading = true
    })
    let res = await API.GET_USER_MENUS()
    runInAction(() => {
      this.loading = false
      let menuList = res.data || []
      if (res.code === 200) {
        this.authMenuList = menuList
      } else {
        this.authMenuList = []
      }
      localStorage.setItem(USER_MENU_LIST_KEY, JSON.stringify(menuList))
    })
  }
}

export default new LoginStore()
