import { URL_PREFIX } from 'utils/constant'
import { get, post } from '../xhr/fetch'

/**
 * 上传发票列表数据
 * @param data
 * @returns
 */
export const GET_UPLOAD_INVOICE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/finan/invc-upload/page-list`, data)
}

/**
 * 获取发票申请信息
 * @param data
 * @returns
 */
export const GET_INVOICE_INFO = (data: any) => {
  return get(`/${URL_PREFIX}/finan/invoice/getInfo`, data)
}
/**
 * 获取投保单-保单[财务]信息
 * @param data
 * @returns
 */
export const GET_FINANCE_INFO = (data: any) => {
  return post(`/${URL_PREFIX}/finan/fin-bill/getInfo`, data)
}

/**
 * 完成开票
 * @param data
 * @returns
 */
export const COMPLETE_INVOICE = (data: any) => {
  return post(`/${URL_PREFIX}/finan/complete-invoice`, data)
}

/**
 * 待销账列表数据
 * @param data
 * @returns
 */
export const GET_WRITE_OFF_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/finan/write-off/page-list`, data)
}

/**
 * 确认销账
 * @param data
 * @returns
 */
export const CONFIRM_WRITE_OFF = (data: any) => {
  return post(`/${URL_PREFIX}/finan/confirm-write-off`, data)
}

/**
 * 批量销账
 * @param data
 * @returns
 */
export const BATCH_WRITE_OFF = (data: any) => {
  return post(`/${URL_PREFIX}/finan/batch-write-off`, data)
}

/**
 * 处理开票申请
 * @param data
 * @returns
 */
export const HANDLE_INVOICE_APPLY = (data: any) => {
  return post(`/${URL_PREFIX}/finan/handle-invc-apply`, data)
}

/**
 * 退出开票操作
 * @param data
 * @returns
 */
export const EXIT_INVOICE_OPERATION = (data: any) => {
  return post(`/${URL_PREFIX}/finan/exit-invc-opt`, data)
}
