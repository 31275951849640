import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_INSURE_CORP_PAGE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/insure-corp/page-list`, data)
}

export const GET_INSURE_CORP_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/insure-corp/list`, data)
}

export const ADD_INSURE_CORP = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/insure-corp/input`, data)
}

export const GET_INSURE_CORP_INFO = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/insure-corp/getInfo`, data)
}

export const UPDATE_INSURE_CORP = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/insure-corp/update`, data)
}

export const ENABLE_INSURE_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-corp/enable`, data)
}

export const DISABLE_INSURE_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-corp/disable`, data)
}

export const DEL_INSURE_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-corp/del`, data)
}

export const GET_INSURE_SUB_CORP_PAGE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/insure-sub-corp/page-list`, data)
}

export const ADD_INSURE_SUB_CORP = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/insure-sub-corp/input`, data)
}

export const UPDATE_INSURE_SUB_CORP = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/insure-sub-corp/update`, data)
}

export const GET_INSURE_SUB_CORP_INFO = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/insure-sub-corp/getInfo`, data)
}

export const ENABLE_INSURE_SUB_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-sub-corp/enable`, data)
}

export const DISABLE_INSURE_SUB_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-sub-corp/disable`, data)
}

export const DEL_INSURE_SUB_CORP = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/insure-sub-corp/del`, data)
}

export const CHECK_INSURE_SUB_CORP_EXIST = (data: any) => {
  return post(
    `/${URL_PREFIX}/insure-set/insure-sub-corp/check-corp-exist`,
    data
  )
}
