import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/CustomerManageApi'
import * as PRODUCT_API from 'service/api/ProductManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'
import { ICustomer } from 'models/CustomerManage.model'

class CustomerManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  customerTable = new table(API.GET_CUSTOMER_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.customerTable.init(param, isPageNum, isResetParam)
    this.customerTable.getList(pageNo, pageSize)
  }

  addCustomer = async (data: ICustomer) => {
    return await API.ADD_CUSTOMER(data)
  }
  updateCustomer = async (data: ICustomer) => {
    return await API.UPDATE_CUSTOMER(data)
  }

  delCustomer = async (id: string) => {
    return await API.DEL_CUSTOMER(id)
  }

  getAuthProductList = async (data: ICustomer) => {
    let res = await API.GET_AUTH_PRODUCT_LIST(data)
    if (res.code === 200) {
      return res.data || []
    }
    return []
  }

  getNotAuthProductList = async (data: ICustomer) => {
    let res = await API.GET_NOT_AUTH_PRODUCT_LIST(data)
    if (res.code === 200) {
      return res.data || []
    }
    return []
  }

  getProductList = async () => {
    let res = await PRODUCT_API.GET_PRODUCT_LIST({ pageSize: 1000, pageNo: 1 })
    if (res.code === 200) {
      return res.data?.rows || []
    }
    return []
  }

  authProduct = async (data: any) => {
    return await API.AUTH_PRODUCT(data)
  }

  resetCustomerPassword = async (data: any) => {
    return await API.RESET_CUSTOMER_PASSWORD(data)
  }

  getClientInfoById = async (data: any) => {
    return await API.GET_CLIENT_INFO_BY_ID(data)
  }

  checkAccountExist = async (data: any) => {
    return await API.CHECK_ACCOUNT_EXIST(data)
  }
}

export default new CustomerManageStore()
