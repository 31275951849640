import { URL_PREFIX } from 'utils/constant'
import { get, post } from '../xhr/fetch'

/**
 * 申请开票列表数据
 * @param data
 * @returns
 */
export const GET_APPLY_INVOICE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/apply/page-list`, data)
}

/**
 * 获取发票申请信息
 * @param data
 * @returns
 */
export const GET_APPLY_INVOICE_INFO = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/getInfo`, data)
}

/**
 * 获取开票公司信息
 * @param data
 * @returns
 */
export const GET_INSURANCE_CORP_INFO = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/invc-corp-info`, data)
}

/**
 * 开票历史-记录列表数据
 * @param data
 * @returns
 */
export const GET_INVOICE_RECORD_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/record/page-list`, data)
}

/**
 * 开票历史-记录列表数据导出
 * @param data
 * @returns
 */
export const EXPORT_INVOICE_RECORD_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/record/list-export`, data)
}

/**
 * 开票退回列表数据
 * @param data
 * @returns
 */
export const GET_INVOICE_REJECT_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/invoice/reject-inv/page-list`, data)
}

/**
 * 退回开票申请
 * @param data
 * @returns
 */
export const REJECT_INVOICE_APPLY = (data: any) => {
  return post(`/${URL_PREFIX}/invoice/reject-apply`, data)
}

/**
 * 提交开票申请
 * @param data
 * @returns
 */
export const APPLY_INVOICE = (data: any) => {
  return post(`/${URL_PREFIX}/invoice/submit-apply`, data)
}
