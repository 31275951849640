import { downLoadFile, get, post } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const SUBMIT_INSURE = (data: any) => {
  return post(`/${URL_PREFIX}/fast-ci/submit`, data)
}

export const SAVE_INSURE = (data: any) => {
  return post(`/${URL_PREFIX}/fast-ci/temp-save`, data)
}

export const GET_COUNTRY_LIST = () => {
  return post(`/${URL_PREFIX}/common-data/country-data`)
}

export const GET_CURRENCY_LIST = () => {
  return get(`/${URL_PREFIX}/fast-ci/curr-rate-list`)
}

export const UPLOAD_INSURE_INFO = (data: any) => {
  return post(`/${URL_PREFIX}/fast-ci/insure-doc-upload`, data)
}

export const GET_INSURE_PRODUCT_ITEM_LIST = () => {
  return post(`/${URL_PREFIX}/fast-ci/obtain-insu-part`)
}

export const MATCH_INSURE_PRODUCT = (data: any) => {
  return post(`/${URL_PREFIX}/fast-ci/mate-insu-product`, data)
}

export const DOWNLOAD_PREVIEW_FILE = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/my-policy/review-item-dl`, data)
}

export const GET_MAIN_RISK_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/fast-ci/main-risk/list`, data)
}

export const GET_EXTRA_RISK_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/fast-ci/extra-risk/list`, data)
}

export const GET_LETTER_LINK = () => {
  return post(`/${URL_PREFIX}/fast-ci/bkdt-letter-templ-link`)
}
