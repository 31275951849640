import JSEncrypt from 'jsencrypt'
import {
  PUBLIC_KEY,
  REMEMBER_PRIVATE_KEY,
  REMEMBER_PUBLIC_KEY
} from './constant'

//加密
export const encode = (value: string) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(PUBLIC_KEY)
  let encodeValue = encrypt.encrypt(value)
  return encodeValue
}

//加密
export const encodeLogin = (value: string) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(REMEMBER_PUBLIC_KEY)
  let encodeValue = encrypt.encrypt(value)
  return encodeValue
}

// 解密
export const decryptLogin = (value: string) => {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(REMEMBER_PRIVATE_KEY) // 设置私钥
  let decodeValue = encryptor.decrypt(value) // 对数据进行解密
  return decodeValue
}
