export const URL_PREFIX = 'insure-system'

export const USER_INFO_KEY = `${URL_PREFIX}_user_info`
export const TOKEN_KEY = `${URL_PREFIX}_token`
export const USER_MENU_LIST_KEY = `${URL_PREFIX}_user_menu_list`
export const CLIENT_TYPE_KEY = `${URL_PREFIX}_client_type`
export const REMEMBER_KEY = `${URL_PREFIX}_remember_pwd`
export const USER_KEY = `${URL_PREFIX}_username`
export const PWD_KEY = `${URL_PREFIX}_pwd`
export const ADMIN_REMEMBER_KEY = `${URL_PREFIX}_admin_remember_pwd`
export const ADMIN_USER_KEY = `${URL_PREFIX}_admin_username`
export const ADMIN_PWD_KEY = `${URL_PREFIX}_admin_pwd`

export const PUBLIC_KEY =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAv65cZ+Hy8Z0xjosyUwVLFHtLCs/YYcLcDoorZbma3s9LgLs1viu5L99dUUrjS4F7UqGi2eIy+ExI6Y3Ps+JWSxAT8sQRXuMUVKpmwr1H6/4QI3BT+ZJ0fnSs+YY9Ev9qB/8ClyH9/QvDy5THAR05W7S3KBgiHKG4ktpmBNsRb628ur3MUexPEBZFC997mjUPGaU6v7yvbGuAoXgj5inwMVSUWX7mW9QNWzOW4aBhOWWhSJcPmQ4YxKyYmSYa0E/0Rgd6KMFgvW4ir0bYtXKICCGEIWQtN3YGytbM+pyOSc68gJ9zdzn45otcbGdF3LdWlvgV5dH6rhbe4w5VLqzsbwIDAQAB'

export const REMEMBER_PUBLIC_KEY =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlobGO8Ce4qGcTApZxBelEsMxukZ7ct974Fh70mXqJoeYD32BYPX4IY1e3jmpu4qoCqDmizL209EhGVrqNYdJU5nfadSqUWMskTx77V69GnD/ekGPm1yBgD7lloVPjYYUzqSsd+orb4d60Bpniq0B50+jkWlm5Hle4iqKQ2kfaeIMGbzU5QAsY2J1a19rWhGHJilb7KF6RKb7fwA2FvuceudtnfwVC6/D/xtiHqgfGhOxKhcYWlY3b9NNh8anlOoHYmrLh05J+DxXWpj/dZMKZ87DNcGQXzdhtcXlh5KuGeMO16fgQG7uySCOfwhSQ9py7cd4N5k2MkXekxXXRY4dyQIDAQAB'
export const REMEMBER_PRIVATE_KEY =
  'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCWhsY7wJ7ioZxMClnEF6USwzG6Rnty33vgWHvSZeomh5gPfYFg9fghjV7eOam7iqgKoOaLMvbT0SEZWuo1h0lTmd9p1KpRYyyRPHvtXr0acP96QY+bXIGAPuWWhU+NhhTOpKx36itvh3rQGmeKrQHnT6ORaWbkeV7iKopDaR9p4gwZvNTlACxjYnVrX2taEYcmKVvsoXpEpvt/ADYW+5x6522d/BULr8P/G2IeqB8aE7EqFxhaVjdv002HxqeU6gdiasuHTkn4PFdamP91kwpnzsM1wZBfN2G1xeWHkq4Z4w7Xp+BAbu7JII5/CFJD2nLtx3g3mTYyRd6TFddFjh3JAgMBAAECggEBAIJbrYlJw0+ZjfX8cMj1gSvCh2o+Z+KP0IP1nSYUxLchbnrbwhPqcDz+ZU4mueuEpQqvVXjvZfsCfZurtHEu/D2+YFESr31UWeBddSWYikQ/38BMzsVJU41w6vtz7/UsTRC4Y/Rlg8BOn/lhJJJgSem8encPxN/QjG9pT9wZSe8zUl0uT7aDia+HDMw9i+TboIEP7hg+oLbzxbeccVWsR132tMWovLCo2fBz/zBXkpyuCcrBwtnV8AomX4cwSZ2ENhb5yA2DxvbgQTXkuynM9MmLi1Fip1ACbicPF5k/pzNzj/11D19Z1pEwLU8J/GzF0Hi1/y5vkunnlSRikOZK+AECgYEA2adnH0yDZ3KywWnTOhHVpBGq3MwYGfHBrgCVFhevacmfX1NFbEfdK7ZLaeDShUJ1O6z295iqLwoe6lUDEyfH9qWHPID9XuQM3BeLDxrs/FBw4ODwMGOBS+ae85vGT4bz96NuCzgdkRsQZFv/JcuaOnQm4vqGohuNawoO6cukLsECgYEAsQvMWbio74F9QX7pc6X7qzyLtQ3lVS9HQCCbi7xDiHkI5V7guRGYak6hml3iACh7HwuWmn0buSfhoS8ezBiiKpiNd0jNWlCTeH3mL/D1DMfs+PO0xmWApxPsVQZp8o9G5374Q2T6EcNSjGXd+7UYz5pO+pRP3I6whF5lENW/uQkCgYA2+VJRlHjIIy9FLN5+F00XwKKgXChmPSrHMTx2tQrINHW9lQG+5LPXO5b2qiNiJzQHG5vRU80ATpUaaNh6+RxHju2GeaoFUqJw4L1t1kYvQH4SPp5MCoX4zsotC5ZeNd7fGHdN/CFzqhtMzfO27yMISpgb0l05Cq6Ab6zunlHcwQKBgFtq7Cmn4BNLmZGoJoNAwaK5sKnccv55WyERtT+CBp0BYkaNpxvoqfq6f1dmArxyKqDtKua6kwYYQbkoy443agT0oJdnUCHCWWNuHt3CXcYrtYEW1dFbuAKNlwqQNUS6FrKSLT8fdMCXSpiUQPsnl5ga5Y0d597LDvpKg4Ud2dGBAoGADvz6+yA6nyhetosyLGsK1wme43sgZayz8mP11SbBfWk1qrm0pc4meVyvxc13C1tWtZq7lWOwKDMKJKTuK+1vqXxN6FKIWCYiMY8r2dHza75FQ+xhxe8DG6N6HsD4wIjZQ2P534ldi0KiOiGT4l1YYGVjl93xZUQVultjdKINVSk='
