import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

/**
 * Map结构转化为Array结构
 * @param map
 * @returns
 */
export const mapToArray = (map: Map<any, any>) => {
  const list: any[] = []
  map.forEach((v, k) => {
    if (
      typeof v === 'string' ||
      typeof v === 'number' ||
      typeof v === 'boolean'
    ) {
      list.push({ value: k, label: v })
    } else if (v.constructor === Object) {
      list.push({ value: k, ...v })
    }
  })
  return list
}

// 模拟a标签文件下载
export const downloadFile = (
  url: string,
  filename: string,
  suffix: string = ''
) => {
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  let name = filename
  if (suffix && suffix !== '') {
    name += suffix
  }
  a.download = name
  a.href = url
  a.click()
  document.body.removeChild(a)
}

// 下载文件流
export const downloadFileStream = (data: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  let el = document.createElement('a')
  el.href = url
  el.setAttribute('download', `${name}`)
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
  window.URL.revokeObjectURL(url) // 释放掉blob对象
}

/**
 * 获取第一个表格的可视化高度
 * @param {*} extraHeight 额外的高度(表格底部的内容高度 Number类型,默认为74)
 * @param {*} id 当前页面中有多个table时需要制定table的id
 */
export function getTableScroll({
  extraHeight,
  id
}: {
  extraHeight?: number
  id?: string
}) {
  if (typeof extraHeight == 'undefined') {
    //  默认底部分页64 + 边距15
    extraHeight = 79
  }
  let tHeader = null
  if (id) {
    tHeader = document.getElementById(id)
      ? document
          .getElementById(id)
          ?.getElementsByClassName('ant-table-thead')[0]
      : null
  } else {
    tHeader = document.getElementsByClassName('ant-table-thead')[0]
  }
  //表格内容距离顶部的距离
  let tHeaderBottom = 0
  if (tHeader) {
    tHeaderBottom = tHeader.getBoundingClientRect().bottom
  }
  //窗体高度-表格内容顶部的高度-表格内容底部的高度
  // let height = document.body.clientHeight - tHeaderBottom - extraHeight
  let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
  return height
}

// // 空运、陆运倒签函只能提前1天, 不包含当天，除了起运日期当天不需要上传倒签函，起运日期之前的日期需要上传倒签函
// 海运不包含当天，超过3天需要上传倒签函，
// 比如，起运日期为6月30日，6月27、28、29包含30日，不需要上传倒签函，6月27日之前需要上传倒签函
// 判断date是否在当前日期到days天后内
export const checkBetweenDate = (date: any, days: number) => {
  let endDate = dayjs().subtract(days, 'day')
  let result = dayjs(date).isBefore(endDate, 'day')
  return result
}

interface IObject {
  [key: string]: any
}

export const isObjectEqual = (obj1: IObject, obj2: IObject) => {
  const obj1Keys = Object.keys(obj1)
  const obj2Keys = Object.keys(obj2)

  if (obj1Keys.length !== obj2Keys.length) {
    return false
  }

  for (let key of obj1Keys) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

export const filterEmpty = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_key, value]) => value !== null && value !== undefined
    )
  )
}

export const filterObjByKey = (obj: object, keys: string[]) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, _value]) => keys.includes(key))
  )
}
