import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Form, Input, Modal, message } from 'antd'
import useStores from 'utils/store'
import { encode } from 'utils/encode'
import { isCustom } from 'models/Global.model'

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}

interface IProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

const ResetPasswordModal: React.FC<IProps> = (props) => {
  const { visible, setVisible } = props

  const { GlobalStore, LoginStore } = useStores()
  const { modifyCustomerPassword, modifyAdminPassword } = GlobalStore
  const { logout } = LoginStore

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      let { originPwd, newPwd, confirmPwd } = values
      let data = {
        originPwd: encode(originPwd),
        newPwd: encode(newPwd),
        confirmPwd: encode(confirmPwd)
      }

      let actionApi = isCustom() ? modifyCustomerPassword : modifyAdminPassword
      let rs = await actionApi(data)
      setConfirmLoading(false)
      if (rs.code === 200) {
        message.success(`密码修改成功`)
        handleCancel()
        logout()
      }
    })
  }

  const handleCancel = () => {
    resetForm()
    setVisible(false)
  }

  const resetForm = () => {
    formRef.current && form.resetFields()
    setConfirmLoading(false)
  }

  return (
    <>
      {visible && (
        <Modal
          forceRender
          confirmLoading={confirmLoading}
          width={400}
          className="modify-password-modal"
          title="修改密码"
          open={visible}
          onOk={handleOk}
          okText="保存"
          cancelText="取消"
          onCancel={handleCancel}>
          <Form
            {...formItemLayout}
            form={form}
            name="reset-password"
            ref={formRef}
            scrollToFirstError>
            <Form.Item
              name="originPwd"
              label="旧密码"
              rules={[
                {
                  required: true,
                  message: '请输入旧密码'
                }
              ]}>
              <Input.Password placeholder="请输入旧密码" maxLength={128} />
            </Form.Item>
            <Form.Item
              name="newPwd"
              label="新密码"
              rules={[
                {
                  required: true,
                  message: '请输入新密码'
                }
              ]}>
              <Input.Password placeholder="请输入新密码" maxLength={128} />
            </Form.Item>
            <Form.Item
              name="confirmPwd"
              label="确认密码"
              rules={[
                {
                  required: true,
                  message: '请输入密码'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('两次密码不一样!')
                  }
                })
              ]}>
              <Input.Password placeholder="请再次输入密码" maxLength={128} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default observer(ResetPasswordModal)
