import React from 'react'

const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="top">
        <div className="left">
          <div className="title">联系我们</div>
          <p style={{ textIndent: '2em' }}>
            为客户提供便捷且高效的服务，是我们工作的方向和价值评价的标尺，也是帮助成就客户、并实现自我价值的重要方式。
          </p>
          <div className="sub-title">上海乂保网络科技有限公司</div>
          <div>公司地址：上海市懿德路519号上海浦东软件园三林园1号楼</div>
          <div>邮箱：product@yb-cargo.com</div>
          <div>联系电话：17349712312</div>
        </div>
        {/* <div className="right"></div> */}
        <img
          className="right"
          src={require('../../../assets/img/aboutUs/contact_1.png')}
          alt="contact_bg"
        />
      </div>
      <div className="bottom">
        <div>
          版权所有©上海乂保网络科技有限公司 | 乂保网 ICP许可证号：
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'unset', color: 'black' }}>
            沪ICP备2023016471号
          </a>
        </div>
        <div>汇中保险经纪有限公司（保险经纪业务许可证：260134000000800）</div>
      </div>
    </div>
  )
}

export default ContactUs
