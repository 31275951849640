import React from 'react'
import Platform from './Platform'
import './index.less'
import ContactUs from './ContactUs'

const AboutUs = () => {
  return (
    <div className="about-us">
      <Platform />
      <ContactUs />
    </div>
  )
}

export default AboutUs
