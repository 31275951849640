import React from 'react'

const OurProduct = () => {
  return (
    <div className="our-product">
      <div className="title">我们的产品</div>
      <div className="product-list">
        <div className="product">
          <img
            src={require('../../../assets/img/productIntro/product_1.png')}
            alt="product_1"
          />
          <div className="name">货运险</div>
          <div className="product-content">
            包括国内及进出口、以海/水运、航空、铁路、汽车为运输方式的货物财产保险
          </div>
        </div>
        <div className="product">
          <img
            src={require('../../../assets/img/productIntro/product_2.png')}
            alt="product_1"
          />
          <div className="name">责任险</div>
          <div className="product-content">
            包括从事跨境运输的货代责任
            、物流责任、仓储责任、企业雇主责任及诉讼财产保全责任保险等
          </div>
        </div>
        <div className="product">
          <img
            src={require('../../../assets/img/productIntro/product_3.png')}
            alt="product_1"
          />
          <div className="name">信用保险及其他财产保险</div>
          <div className="product-content">
            为从事国际贸易有需要的企业提供信用保障，其他财产险包括企财险、家财险、船舶保险等
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProduct
