import { get, post } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const ADMIN_LOGIN = (data: any) => {
  return post(`${URL_PREFIX}/console/login`, data)
}

export const LOGIN = (data: any) => {
  return post(`${URL_PREFIX}/pdt-store/login`, data)
}

/**
 * 获取授权菜单
 * @returns
 */
export const GET_USER_MENUS = () => {
  return get(`/${URL_PREFIX}/console/getRouters`)
}
