import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import useStores from 'utils/store'
import ResetPasswordModal from './ResetPasswordModal'
import { USER_INFO_KEY } from 'utils/constant'

const UserInfo = () => {
  const { LoginStore } = useStores()
  const { logout } = LoginStore

  let userAllInfo = JSON.parse(localStorage.getItem(USER_INFO_KEY) ?? '{}')

  let { username, orgBaseInfoList } = userAllInfo || {}
  orgBaseInfoList = Array.isArray(orgBaseInfoList) ? orgBaseInfoList : []

  const [modifyPassVisible, setModifyPassVisible] = useState<boolean>(false)

  const items = [
    {
      label: <span onClick={() => onResetPassword()}>修改密码</span>,
      key: 'modifyPwd'
    }, // 菜单项务必填写 key
    { label: <span onClick={() => logout()}>退出登录</span>, key: 'logout' }
  ]

  const onResetPassword = () => {
    setModifyPassVisible(true)
  }

  return (
    <>
      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        overlayClassName="user-menu">
        <div className="user-info">
          <div className="avatar">
            <UserOutlined />
          </div>
          <div>
            <div className="real-name" title={username}>
              {username}
            </div>
            <div className="dept-name">
              {orgBaseInfoList.length > 0 ? orgBaseInfoList[0].name : ''}
            </div>
          </div>
          <CaretDownOutlined />
        </div>
      </Dropdown>
      {modifyPassVisible && (
        <ResetPasswordModal
          visible={modifyPassVisible}
          setVisible={setModifyPassVisible}
        />
      )}
    </>
  )
}

export default UserInfo
