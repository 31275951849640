import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_PRODUCT_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/product/page-list`, data)
}

export const ADD_PRODUCT = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/product/input`, data)
}

export const UPDATE_PRODUCT = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/product/update`, data)
}

export const PUBLISH_PRODUCT = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/product/publish`, data)
}

export const OFF_PRODUCT = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/product/removal`, data)
}

export const DEL_PRODUCT = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/product/del`, data)
}
