import React from 'react'
import { Header } from 'antd/es/layout/layout'
import UserInfo from './Userinfo'

const index = () => {
  return (
    <Header style={{ display: 'flex', alignItems: 'center' }}>
      <div className="logo-wrapper">
        <div className="logo">
          <img src={require('../../assets/img/logo_new.png')} alt="logo" />
        </div>
        {/* <div className="system-title">乂保投保系统</div> */}
      </div>
      <UserInfo />
    </Header>
  )
}

export default index
