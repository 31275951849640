import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_CURRENCY_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/currency-rate/page-list`, data)
}

export const ADD_CURRENCY = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/currency-rate/input`, data)
}
export const UPDATE_CURRENCY = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/currency-rate/update`, data)
}
export const DEL_CURRENCY = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/currency-rate/del`, data)
}
