import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_CUSTOMER_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/pdt-store/page-list`, data)
}

export const ADD_CUSTOMER = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/input`, data)
}

export const UPDATE_CUSTOMER = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/update`, data)
}

export const DEL_CUSTOMER = (id: string) => {
  return postFormData(`/${URL_PREFIX}/insure-set/pdt-store/del`, { id })
}

export const GET_AUTH_PRODUCT_LIST = (data: any) => {
  return postFormData(
    `/${URL_PREFIX}/insure-set/pdt-store/authorized-product`,
    data
  )
}

export const GET_NOT_AUTH_PRODUCT_LIST = (data: any) => {
  return postFormData(
    `/${URL_PREFIX}/insure-set/pdt-store/unauthorized-product`,
    data
  )
}

export const AUTH_PRODUCT = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/product-grant`, data)
}

export const RESET_CUSTOMER_PASSWORD = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/reset-pwd`, data)
}

export const GET_CLIENT_INFO_BY_ID = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/to-client-sys`, data)
}

export const CHECK_ACCOUNT_EXIST = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/pdt-store/check-account-exist`, data)
}
