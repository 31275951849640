import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/MyPolicyApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'

class MyPolicyStore {
  constructor() {
    makeAutoObservable(this)
  }

  myPolicyTable = new table(API.GET_MY_POLICY_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.myPolicyTable.init(param, isPageNum, isResetParam)
    this.myPolicyTable.getList(pageNo, pageSize)
  }

  getMyPolicyInfo = async (data: any) => {
    return await API.GET_MY_POLICY_INFO(data)
  }

  delMyPolicy = async (data: any) => {
    return await API.DEL_MY_POLICY(data)
  }

  downloadEPolicy = async (data: any) => {
    return await API.DOWNLOAD_E_POLICY(data)
  }

  exportMyPolicy = async (data: any) => {
    return await API.EXPORT_MY_POLICY(data)
  }
}

export default new MyPolicyStore()
