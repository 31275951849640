import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import { Form, Input, Button, Row, Col, Checkbox } from 'antd'
import useStores from 'utils/store'
import { CLIENT_TYPE, getToken } from 'models/Global.model'
import '../../styles/login.less'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const AdminLogin = () => {
  const { LoginStore } = useStores()
  const { login, loading, toggleRememberPwd, rememberPwd, userName, passwd } =
    LoginStore
  const navigation = useNavigate()

  useEffect(() => {
    let token = getToken()
    token && navigation('/')
  }, [])

  const onFinish = (values: any) => {
    login(
      values,
      () => {
        navigation('/')
      },
      CLIENT_TYPE.ADMIN
    )
  }

  return (
    <div className="login-page admin">
      <div className="login-container">
        <Form
          className="login-form"
          {...layout}
          name="basic"
          onFinish={onFinish}
          initialValues={{
            username: userName,
            password: passwd
          }}>
          <div className="welcome-title">欢迎登录</div>
          <Form.Item
            name="username"
            label="账户"
            rules={[{ required: true, message: '请输入账户!' }]}>
            <Input className="input" maxLength={30} placeholder="请输入账户" />
          </Form.Item>
          <Form.Item
            name="password"
            label="密码"
            rules={[{ required: true, message: '请输入密码!' }]}>
            <Input.Password
              maxLength={30}
              className="input"
              placeholder="密码"
            />
          </Form.Item>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <span
                className="remember-pass"
                style={{ color: '#607091', fontSize: '14px' }}>
                <Checkbox
                  className="remember-check"
                  onChange={(e) => {
                    toggleRememberPwd(e.target.checked)
                  }}
                  checked={rememberPwd}>
                  记住密码
                </Checkbox>
              </span>
            </Col>
          </Row>

          <Button
            loading={loading}
            className="login-btn"
            type="primary"
            htmlType="submit"
            disabled={loading}>
            登录
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default observer(AdminLogin)
