import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/CurrencyManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'
import { ICurrency } from 'models/CurrencyManage.model'

class CurrencyManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  currencyTable = new table(API.GET_CURRENCY_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.currencyTable.init(param, isPageNum, isResetParam)
    this.currencyTable.getList(pageNo, pageSize)
  }

  addCurrency = async (data: ICurrency) => {
    return await API.ADD_CURRENCY(data)
  }
  updateCurrency = async (data: ICurrency) => {
    return await API.UPDATE_CURRENCY(data)
  }

  delCurrency = async (id: string) => {
    return await API.DEL_CURRENCY(id)
  }
}

export default new CurrencyManageStore()
