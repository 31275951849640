import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/InvoiceManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'

class InvoiceManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  applyInvoiceTable = new table(API.GET_APPLY_INVOICE_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.applyInvoiceTable.init(param, isPageNum, isResetParam)
    this.applyInvoiceTable.getList(pageNo, pageSize)
  }

  getApplyInvoiceInfo = async (data: any) => {
    return await API.GET_APPLY_INVOICE_INFO(data)
  }

  applyInvoice = async (data: any) => {
    return await API.APPLY_INVOICE(data)
  }

  getInsuranceCorpInfo = async (data: any) => {
    return await API.GET_INSURANCE_CORP_INFO(data)
  }

  invoiceRecordTable = new table(API.GET_INVOICE_RECORD_LIST)

  initInvoiceRecord = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.invoiceRecordTable.init(param, isPageNum, isResetParam)
    this.invoiceRecordTable.getList(pageNo, pageSize)
  }

  invoiceRejectTable = new table(API.GET_INVOICE_REJECT_LIST)

  initInvoiceReject = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.invoiceRejectTable.init(param, isPageNum, isResetParam)
    this.invoiceRejectTable.getList(pageNo, pageSize)
  }

  rejectInvoiceApply = async (data: any) => {
    return await API.REJECT_INVOICE_APPLY(data)
  }
}

export default new InvoiceManageStore()
