import LoginStore from './module/LoginStore'
import GlobalStore from './module/GlobalStore'
import CustomerManageStore from './module/CustomerManageStore'
import ProductManageStore from './module/ProductManageStore'
import InsureCorpManageStore from './module/InsureCorpManageStore'
import SysManageStore from './module/SysManageStore'
import MyPolicyStore from './module/MyPolicyStore'
import RiskManageStore from './module/RiskManageStore'
import CurrencyManageStore from './module/CurrencyManageStore'
import InsureManageStore from './module/InsureManageStore'
import InsurePolicyManageStore from './module/InsurePolicyManageStore'
import InvoiceManageStore from './module/InvoiceManageStore'
import FinanceManageStore from './module/FinanceManageStore'

const stores = {
  LoginStore,
  GlobalStore,
  CustomerManageStore,
  ProductManageStore,
  InsureCorpManageStore,
  SysManageStore,
  MyPolicyStore,
  RiskManageStore,
  CurrencyManageStore,
  InsureManageStore,
  InsurePolicyManageStore,
  InvoiceManageStore,
  FinanceManageStore
}
export default stores
