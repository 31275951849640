import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/FinanceManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'

class FinanceManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  uploadInvoiceTable = new table(API.GET_UPLOAD_INVOICE_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.uploadInvoiceTable.init(param, isPageNum, isResetParam)
    this.uploadInvoiceTable.getList(pageNo, pageSize)
  }

  getInvoiceInfo = async (data: any) => {
    return await API.GET_INVOICE_INFO(data)
  }

  getFinanceInvoiceInfo = async (data: any) => {
    return await API.GET_FINANCE_INFO(data)
  }

  handleInvoiceApply = async (data: any) => {
    return await API.HANDLE_INVOICE_APPLY(data)
  }

  exitInvoiceOperation = async (data: any) => {
    return await API.EXIT_INVOICE_OPERATION(data)
  }

  completeInvoice = async (data: any) => {
    return await API.COMPLETE_INVOICE(data)
  }

  writeOffTable = new table(API.GET_WRITE_OFF_LIST)

  initWriteOff = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.writeOffTable.init(param, isPageNum, isResetParam)
    this.writeOffTable.getList(pageNo, pageSize)
  }

  confirmWriteOff = async (data: any) => {
    return await API.CONFIRM_WRITE_OFF(data)
  }

  batchWriteOff = async (data: any) => {
    return await API.BATCH_WRITE_OFF(data)
  }
}

export default new FinanceManageStore()
