import { makeAutoObservable } from 'mobx'
import { IInit } from 'models/Global.model'
import * as API from 'service/api/InsurePolicyManageApi'
import table from 'store/common/table'

class InsureManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  insurePolicyTable = new table(API.GET_INSURE_POLICY_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.insurePolicyTable.init(param, isPageNum, isResetParam)
    this.insurePolicyTable.getList(pageNo, pageSize)
  }

  handleInsure = async (data: any) => {
    return await API.HANDLE_INSURE(data)
  }

  getInsurePolicyInfo = async (data: any) => {
    return await API.GET_INSURE_POLICY_INFO(data)
  }

  cancelInsurePolicy = async (data: any) => {
    return await API.CANCEL_INSURE_POLICY(data)
  }

  revokeInsurePolicy = async (data: any) => {
    return await API.REVOKE_INSURE_POLICY(data)
  }

  completeInsure = async (data: any) => {
    return await API.COMPLETE_INSURE(data)
  }

  exportInsurePolicyList = async (data: any) => {
    return await API.EXPORT_INSURE_POLICY_LIST(data)
  }

  downloadEPolicy = async (data: any) => {
    let res = await API.DOWNLOAD_E_POLICY(data)
    return res
  }
}

export default new InsureManageStore()
