import { defaultRoleList } from 'mock/roleList'
import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_USER_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/system/user/page-list`, data)
}

export const ADD_USER = (data: any) => {
  return post(`/${URL_PREFIX}/system/user/input`, data)
}

export const UPDATE_USER = (data: any) => {
  return post(`/${URL_PREFIX}/system/user/update`, data)
}

export const DEL_USER = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/user/del`, data)
}

export const DISABLE_USER = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/user/disable`, data)
}

export const ENABLE_USER = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/user/enable`, data)
}

export const GET_ROLE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/system/role/page-list`, data)
}

export const ADD_ROLE = (data: any) => {
  return post(`/${URL_PREFIX}/system/role/input`, data)
}

export const UPDATE_ROLE = (data: any) => {
  return post(`/${URL_PREFIX}/system/role/update`, data)
}

export const DEL_ROLE = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/role/del`, data)
}

export const DISABLE_ROLE = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/role/disable`, data)
}

export const ENABLE_ROLE = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/role/enable`, data)
}

export const GET_ROLE_USER_LIST = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/role/role-user`, data)
}

export const RELATE_ROLE_USER_LIST = (data: any) => {
  return post(`/${URL_PREFIX}/system/role/authorizeToUser`, data)
}

export const GET_ROLE_MENU_LIST = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/role/role-menu`, data)
}

export const AUTH_ROLE_MENU_LIST = (data: any) => {
  return post(`/${URL_PREFIX}/system/role/role-menu-grant`, data)
}

export const RESET_PASSWORD = (data: any) => {
  return post(`/${URL_PREFIX}/system/user/reset-pwd`, data)
}

export const GET_MENU_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/system/menu/treelist`, data)
}

export const ADD_MENU = (data: any) => {
  return post(`/${URL_PREFIX}/system/menu/input`, data)
}

export const UPDATE_MENU = (data: any) => {
  return post(`/${URL_PREFIX}/system/menu/update`, data)
}

export const DEL_MENU = (data: any) => {
  return postFormData(`/${URL_PREFIX}/system/menu/del`, data)
}
