import React, { useCallback, useEffect, useState } from 'react'
import { Menu, Layout } from 'antd'
import type { MenuProps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRoute } from 'router'
// import { menus } from 'router'
interface IProps {
  menus: IRoute[]
}

const { Sider } = Layout

const LeftMenu: React.FC<IProps> = (props) => {
  const { menus } = props
  const navigateTo = useNavigate()
  const location = useLocation()
  let { pathname } = location
  let arr = pathname.split('/')

  const [openKeys, setOpenKeys] = useState([arr[1]])
  const [selectedKeys, setSelectedKeys] = useState([arr[arr.length - 1]])

  const onOpenChange: MenuProps['onOpenChange'] = useCallback(
    (keys: string[]) => {
      const latestOpenKey = keys.find(
        (key: string) => openKeys.indexOf(key) === -1
      )
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    },
    [openKeys]
  )

  const menuClick = (e: { key: string; keyPath: string[] }) => {
    let path = [...e.keyPath].reverse().join('/')
    navigateTo(path)
    setSelectedKeys([e.key])
  }

  useEffect(() => {
    let arr = pathname.split('/')
    let keys = []
    if (arr.length > 2) {
      keys = arr.slice(1)
    } else {
      keys = [arr[arr.length - 1]]
    }
    setOpenKeys([arr[1]])
    setSelectedKeys(keys)
  }, [pathname])

  return (
    <Sider width={200} className="left-menu">
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        items={menus}
        onClick={menuClick}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </Sider>
  )
}

export default LeftMenu
