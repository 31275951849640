import React from 'react'
import OurProduct from './OurProduct'
import './index.less'
import OurSerivce from './OurSerivce'

const ProductIntro = () => {
  return (
    <div className="product-introduce">
      <OurProduct />
      <OurSerivce />
    </div>
  )
}

export default ProductIntro
