import { downLoadFile, get, post } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_INSURE_POLICY_INFO = (data: any) => {
  return post(`/${URL_PREFIX}/insure/cargo/getInfo`, data)
}

export const HANDLE_INSURE = (data: any) => {
  return post(`/${URL_PREFIX}/insure/cargo/handle-insure`, data)
}

export const GET_INSURE_POLICY_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure/cargo/opt/page-list`, data)
}

export const CANCEL_INSURE_POLICY = (data: any) => {
  return post(`/${URL_PREFIX}/insure/cargo/exit-insure-opt`, data)
}

export const REVOKE_INSURE_POLICY = (data: any) => {
  return post(`/${URL_PREFIX}/insure/cargo/revoke-policy`, data)
}

export const COMPLETE_INSURE = (data: any) => {
  return post(`/${URL_PREFIX}/insure/cargo/complete-insure`, data)
}

export const EXPORT_INSURE_POLICY_LIST = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/insure/cargo/opt/list-export`, data)
}

export const DOWNLOAD_E_POLICY = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/insure/cargo/epolicy-dl`, data, 'post')
}
