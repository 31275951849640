import { mapToArray } from 'utils/util'

export enum PAGE_TYPE {
  HOME = 'home',
  ABOUT_US = 'aboutUs',
  PRODUCT_INTRO = 'productIntro'
}

export interface IMenuItem {
  label: string
  value: string
}

const menuMap = new Map<string, string>([
  [PAGE_TYPE.HOME, '首页'],
  [PAGE_TYPE.ABOUT_US, '关于我们'],
  [PAGE_TYPE.PRODUCT_INTRO, '产品介绍']
])

export const menuList: IMenuItem[] = mapToArray(menuMap)
