import { makeAutoObservable } from 'mobx'
import * as API from 'service/api/ProductManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'

class ProductManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  productTable = new table(API.GET_PRODUCT_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.productTable.init(param, isPageNum, isResetParam)
    this.productTable.getList(pageNo, pageSize)
  }

  addProduct = async (data: any) => {
    return await API.ADD_PRODUCT(data)
  }
  updateProduct = async (data: any) => {
    return await API.UPDATE_PRODUCT(data)
  }

  publishProduct = async (data: any) => {
    return await API.PUBLISH_PRODUCT(data)
  }

  offProduct = async (data: any) => {
    return await API.OFF_PRODUCT(data)
  }

  delProduct = async (data: any) => {
    return await API.DEL_PRODUCT(data)
  }
}

export default new ProductManageStore()
