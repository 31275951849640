import { CLIENT_TYPE_KEY, TOKEN_KEY } from 'utils/constant'
import { mapToArray } from 'utils/util'

export interface IInit {
  param?: any
  pageNo?: number
  pageSize?: number
  isResetParam?: boolean
  isPageNum?: boolean
}

export interface IPremiumStat {
  policyHolderName: string
  premiumFeeSum: number
  rank: number
  ciPolicyQuantity: number
  insureTime: string
  insureTimeEnd: string
  insureTimeStart: string
}

export enum STATUS {
  ENABLE = '0',
  DISABLE = '1'
}

export const statusMap = new Map<STATUS, { name: string; color: string }>([
  [STATUS.DISABLE, { name: '禁用', color: '#D2DAE7' }],
  [STATUS.ENABLE, { name: '启用', color: '#49C229' }]
])

export const statusList = mapToArray(statusMap)

export enum DATE_TYPE {
  YYYYMMDD = 'YYYY-MM-DD'
}

export enum CLIENT_TYPE {
  CUSTOM = '0',
  ADMIN = '1'
}

export const isCustom = () =>
  localStorage.getItem(CLIENT_TYPE_KEY) === CLIENT_TYPE.CUSTOM

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}
