import React from 'react'

const OurSerivce = () => {
  return (
    <div className="our-service">
      <div className="title">我们的服务</div>
      <div className="service-list">
        <div className="service service1">
          <div className="top">
            <div className="gray"></div>
            <div className="bg">
              <img
                src={
                  require('../../../assets/img/productIntro/lock_icon.svg')
                    .default
                }
                alt="lock_icon"
                width={64}
              />
            </div>
          </div>
          <div className="service-name">理赔服务</div>
          <div className="service-content">提赔协助，与您相伴</div>
        </div>

        <div className="service service2">
          <div className="service-name">保险询价</div>
          <div className="service-content">货比三家，择优而取</div>
          <div className="top">
            <div className="gray"></div>
            <div className="bg">
              <img
                src={
                  require('../../../assets/img/productIntro/layers_icon.svg')
                    .default
                }
                alt="lock_icon"
                width={64}
              />
            </div>
          </div>
        </div>
        <div className="service service3">
          <div className="top">
            <div className="gray"></div>
            <div className="bg">
              <img
                src={
                  require('../../../assets/img/productIntro/tags_icon.svg')
                    .default
                }
                alt="lock_icon"
                width={64}
              />
            </div>
          </div>
          <div className="service-name">产品定制</div>
          <div className="service-content">倾听需求，专属定制</div>
        </div>
        <div className="service service4">
          <div className="service-name">培训沙龙</div>
          <div className="service-content">网聚经验，共促成长</div>
          <div className="top">
            <div className="gray"></div>
            <div className="bg">
              <img
                src={
                  require('../../../assets/img/productIntro/room_icon.svg')
                    .default
                }
                alt="lock_icon"
                width={64}
              />
            </div>
          </div>
        </div>
        <div className="service service5">
          <div className="top">
            <div className="gray"></div>
            <div className="bg">
              <img
                src={
                  require('../../../assets/img/productIntro/download_icon.svg')
                    .default
                }
                alt="lock_icon"
                width={64}
              />
            </div>
          </div>
          <div className="service-name">资料模板</div>
          <div className="service-content">海量数据，为您解忧</div>
        </div>
      </div>
    </div>
  )
}

export default OurSerivce
