import React from 'react'
import { Spin } from 'antd'
import './index.less'

interface IProps {
  content?: string
}

const Loading: React.FC<IProps> = (props) => {
  return (
    <div className="loading-container">
      <Spin />
      <div className="loading-content">{props.content ?? '加载中...'}</div>
    </div>
  )
}

export default Loading
