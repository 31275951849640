import React from 'react'
import { Result } from 'antd'

const NoAuth = () => {
  return (
    <Result status="403" title="403" subTitle="对不起，您没有访问系统的权限" />
  )
}

export default NoAuth
