import React, { ReactNode, Suspense, lazy } from 'react'
import {
  SettingFilled,
  MoneyCollectFilled,
  UserOutlined
} from '@ant-design/icons'
import BasicLayout from 'Layout/BasicLayout'
import Login from 'pages/Login'
import Loading from 'components/Loading'
import AdminLogin from 'pages/AdminLogin'
import { CLIENT_TYPE } from 'models/Global.model'

const CustomerManage = lazy(() => import('pages/CustomerManage'))
const ProductManage = lazy(() => import('pages/ProductManage'))
const UploadInvoiceList = lazy(
  () => import('pages/FinanceManage/UploadInvoiceList')
)
const WriteOffList = lazy(() => import('pages/FinanceManage/WriteOffList'))
const ApplyInvoiceList = lazy(
  () => import('pages/InvoiceManage/ApplyInvoiceList')
)
const InvoiceRecord = lazy(() => import('pages/InvoiceManage/InvoiceRecord'))
const RejectInvoice = lazy(() => import('pages/InvoiceManage/RejectInvoice'))
const InsureCorp = lazy(() => import('pages/InsureCorpManage/InsureCorp'))
const InsureSubCorp = lazy(() => import('pages/InsureCorpManage/InsureSubCorp'))
const UserList = lazy(() => import('pages/SysManage/UserList'))
const RoleList = lazy(() => import('pages/SysManage/RoleList'))
const DomesticTransport = lazy(
  () => import('pages/QuickInsurance/DomesticTransport')
)
const InternationalExport = lazy(
  () => import('pages/QuickInsurance/InternationalExport')
)
const InternationalImport = lazy(
  () => import('pages/QuickInsurance/InternationalImport')
)
const OverseasTransport = lazy(
  () => import('pages/QuickInsurance/OverseasTransport')
)

const CustomerCenter = lazy(() => import('pages/CustomerCenter'))
const MyPolicy = lazy(() => import('pages/MyPolicy'))
const PolicyDetail = lazy(() => import('pages/MyPolicy/InsurePolicyDetail'))
const MainRiskList = lazy(() => import('pages/RiskManage/MainRiskList'))
const ExtraInsurance = lazy(() => import('pages/RiskManage/ExtraInsurance'))
const InsurePolicyList = lazy(() => import('pages/InsurePolicyManage'))
const InsurePolicyDetail = lazy(
  () => import('pages/InsurePolicyManage/InsurePolicyDetail')
)
const CurrencyManage = lazy(() => import('pages/CurrencyManage'))
const MenuList = lazy(() => import('pages/SysManage/MenuList'))
const PremiumStat = lazy(() => import('pages/PremiumStat'))

const withWrapperComponent = (comp: ReactNode) => (
  <Suspense fallback={<Loading />}>{comp}</Suspense>
)

export enum ROUTE_TYPE {
  MENU = 'menu',
  ROUTE = 'route'
}

export interface IRoute {
  path: string
  key: string
  element?: ReactNode
  label: string
  icon?: ReactNode
  children?: IRoute[]
  routes?: IRoute[]
  breadcrumbLabel?: string[]
  breadcrumbUrl?: string[]
  type?: ROUTE_TYPE
}

const customMenus: IRoute[] = [
  {
    key: 'customerCenter',
    path: 'customerCenter',
    label: '个人中心',
    icon: <UserOutlined />,
    element: withWrapperComponent(<CustomerCenter />)
  },
  {
    key: 'myPolicy',
    path: 'myPolicy',
    label: '我的保单',
    icon: <MoneyCollectFilled />,
    element: withWrapperComponent(<MyPolicy />),
    routes: [
      {
        key: 'policyDetail',
        path: 'myPolicy/policyDetail',
        label: '投保单详情',
        type: ROUTE_TYPE.ROUTE,
        element: withWrapperComponent(<PolicyDetail />)
      }
    ]
  },
  {
    key: 'insure',
    path: 'insure',
    label: '我要投保',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'domesticTransport',
        path: 'insure/domesticTransport',
        label: '国内货运险',
        element: withWrapperComponent(<DomesticTransport />)
      },
      {
        key: 'internationalExport',
        path: 'insure/internationalExport',
        label: '国际出口',
        element: withWrapperComponent(<InternationalExport />)
      },
      {
        key: 'internationalImport',
        path: 'insure/internationalImport',
        label: '国际进口',
        element: withWrapperComponent(<InternationalImport />)
      },
      {
        key: 'overseasTransport',
        path: 'insure/overseasTransport',
        label: '境外运输',
        element: withWrapperComponent(<OverseasTransport />)
      }
    ]
  }
]

const adminMenus: IRoute[] = [
  {
    key: 'insureManage',
    path: 'insureManage',
    label: '操作管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'insurePolicyList',
        path: 'insureManage/insurePolicyList',
        label: '投保单列表',
        element: withWrapperComponent(<InsurePolicyList />),
        routes: [
          {
            key: 'insurePolicyDetail',
            path: 'insureManage/insurePolicyList/insurePolicyDetail',
            label: '投保单详情',
            type: ROUTE_TYPE.ROUTE,
            element: withWrapperComponent(<InsurePolicyDetail />)
          }
        ]
      }
    ]
  },
  {
    key: 'customerManage',
    path: 'customerManage',
    label: '客户管理',
    icon: <UserOutlined />,
    children: [
      {
        key: 'customerList',
        path: 'customerManage/customerList',
        label: '客户列表',
        element: withWrapperComponent(<CustomerManage />)
      }
    ]
  },
  {
    key: 'productManage',
    path: 'productManage',
    label: '产品配置',
    icon: <SettingFilled />,
    children: [
      {
        key: 'productList',
        path: 'productManage/productList',
        label: '产品池',
        element: withWrapperComponent(<ProductManage />)
      }
    ]
  },
  {
    key: 'insureCorpManage',
    path: 'insureCorpManage',
    label: '保险公司管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'insureCorpList',
        path: 'insureCorpManage/insureCorpList',
        label: '保险公司列表',
        element: withWrapperComponent(<InsureCorp />)
      },
      {
        key: 'insureSubCorpList',
        path: 'insureCorpManage/insureSubCorpList',
        label: '保险分公司列表',
        element: withWrapperComponent(<InsureSubCorp />)
      }
    ]
  },
  {
    key: 'financeManage',
    path: 'financeManage',
    label: '财务管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'uploadInvoiceList',
        path: 'financeManage/uploadInvoiceList',
        label: '上传发票列表',
        element: withWrapperComponent(<UploadInvoiceList />)
      },
      {
        key: 'writeOffList',
        path: 'financeManage/writeOffList',
        label: '销账列表',
        element: withWrapperComponent(<WriteOffList />)
      }
    ]
  },
  {
    key: 'sysManage',
    path: 'sysManage',
    label: '系统管理',
    icon: <SettingFilled />,
    children: [
      {
        key: 'userList',
        path: 'sysManage/userList',
        label: '用户列表',
        element: withWrapperComponent(<UserList />)
      },
      {
        key: 'roleList',
        path: 'sysManage/roleList',
        label: '角色列表',
        element: withWrapperComponent(<RoleList />)
      },
      {
        key: 'menuList',
        path: 'sysManage/menuList',
        label: '菜单列表',
        element: withWrapperComponent(<MenuList />)
      }
    ]
  },
  {
    key: 'riskManage',
    path: 'riskManage',
    label: '险种管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'mainRiskList',
        path: 'riskManage/mainRiskList',
        label: '主险列表',
        element: withWrapperComponent(<MainRiskList />)
      },
      {
        key: 'extraRiskList',
        path: 'riskManage/extraRiskList',
        label: '附加险列表',
        element: withWrapperComponent(<ExtraInsurance />)
      }
    ]
  },
  {
    key: 'currencyManage',
    path: 'currencyManage',
    label: '汇率管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'currencyList',
        path: 'currencyManage/currencyList',
        label: '币种列表',
        element: withWrapperComponent(<CurrencyManage />)
      }
    ]
  },
  {
    key: 'premiumStat',
    path: 'premiumStat',
    label: '保费统计',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'premiumStatList',
        path: 'premiumStat/premiumStatList',
        label: '保费统计列表',
        element: withWrapperComponent(<PremiumStat />)
      }
    ]
  },
  {
    key: 'invoiceManage',
    path: 'invoiceManage',
    label: '发票管理',
    icon: <MoneyCollectFilled />,
    children: [
      {
        key: 'invoiceList',
        path: 'invoiceManage/invoiceList',
        label: '申请开票列表',
        element: withWrapperComponent(<ApplyInvoiceList />)
      },
      {
        key: 'invoiceRecord',
        path: 'invoiceManage/invoiceRecord',
        label: '开票历史',
        element: withWrapperComponent(<InvoiceRecord />)
      },
      {
        key: 'rejectInvoice',
        path: 'invoiceManage/rejectInvoice',
        label: '开票退回',
        element: withWrapperComponent(<RejectInvoice />)
      }
    ]
  }
]

//递归路由
export const deepRoute = (list: IRoute[], allRoutes: IRoute[]) => {
  list.forEach((route: IRoute) => {
    if (route.element) {
      allRoutes.push(route)
    }
    if (route.children) {
      deepRoute(route.children, allRoutes)
    }
    if (route.routes) {
      deepRoute(route.routes, allRoutes)
    }
  })
}

export const getMenuList = (list: IRoute[] = []) => {
  let menuList: IRoute[] = []
  list.forEach((route: IRoute) => {
    let { type = ROUTE_TYPE.MENU } = route
    if (type === ROUTE_TYPE.MENU) {
      if (route.children) {
        route.children = getMenuList(route.children)
      }
      menuList.push(route)
    }
  })
  return menuList
}

// 过滤有权限的菜单
export const getAuthRouteList = (
  list: IRoute[] = [],
  authKeyList: string[] = []
) => {
  let authRouteList: IRoute[] = []
  list.forEach((route) => {
    if (authKeyList.includes(route.path) || route.type === ROUTE_TYPE.ROUTE) {
      if (route.children) {
        route.children = getAuthRouteList(route.children, authKeyList)
      }
      authRouteList.push(route)
    }
  })
  return authRouteList
}
// 将菜单扁平
const flatArr = (arr: any[], authList: any[] = []) => {
  if (arr.length === 0) return []
  arr.forEach((item: any) => {
    authList.push(item)
    if (item?.children?.length) {
      return authList.concat(flatArr(item.children, authList))
    }
  })
  return authList
}

export const getRoutes = (clientType: CLIENT_TYPE, list?: IRoute[]) => {
  let isAdmin = clientType === CLIENT_TYPE.ADMIN
  let authKeyList: string[] = []
  let authList: any[] = []
  if (list?.length) {
    authKeyList = flatArr(list, authList).map((item: any) => item.menuPath)
  }
  // 授权菜单及路由
  let authRouteList = getAuthRouteList(adminMenus, authKeyList)
  let menus = isAdmin ? authRouteList : customMenus
  let allRoutes: IRoute[] = []
  deepRoute(menus, allRoutes)
  menus = getMenuList(menus)
  return { allRoutes, menus }
}

const routes = [
  {
    key: 'login',
    label: '登录',
    path: '/login',
    element: <Login />
  },
  {
    key: 'adminLogin',
    label: '登录',
    path: '/adminLogin',
    element: <AdminLogin />
  },
  {
    key: 'basicLayout',
    label: '主页',
    path: '/*',
    element: <BasicLayout />
  }
]

export default routes
