import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import { Form, Input, Button, Menu, Row, Col, Checkbox } from 'antd'
import useStores from 'utils/store'
import { CLIENT_TYPE, getToken } from 'models/Global.model'
import { IMenuItem, PAGE_TYPE, menuList } from 'models/Login.model'
import AboutUs from 'pages/Login/AboutUs'
import ProductIntro from './ProductIntro'
import '../../styles/login.less'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const Login = () => {
  const { LoginStore } = useStores()
  const { login, loading, toggleRememberPwd, rememberPwd, userName, passwd } =
    LoginStore

  let list = menuList.map((menu: IMenuItem) => {
    let { label, value } = menu
    return {
      key: value,
      label
    }
  })

  const [page, setPage] = useState<PAGE_TYPE>(PAGE_TYPE.HOME)

  const navigation = useNavigate()

  useEffect(() => {
    let token = getToken()
    token && navigation('/')
  }, [])

  const onFinish = (values: any) => {
    login(
      values,
      () => {
        navigation('/')
      },
      CLIENT_TYPE.CUSTOM
    )
  }

  const changeMenu = (e: { key: string }) => {
    console.log(e.key)
    setPage(e.key as PAGE_TYPE)
  }

  return (
    <div className="login-page customer">
      <div className="login-header">
        <div className="logo-container">
          <img src={require('../../assets/img/logo_new.png')} alt="logo" />
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[PAGE_TYPE.HOME]}
          items={list}
          onClick={changeMenu}
        />
      </div>
      <div className="login-content">
        {page === PAGE_TYPE.HOME && (
          <div className="login-wrapper">
            <div className="login-container">
              <Form
                className="login-form"
                {...layout}
                name="basic"
                initialValues={{
                  username: userName,
                  password: passwd
                }}
                onFinish={onFinish}>
                <div className="welcome-title">欢迎登录</div>
                <Form.Item
                  name="username"
                  label="账户"
                  rules={[{ required: true, message: '请输入账户!' }]}>
                  <Input
                    className="input"
                    maxLength={30}
                    placeholder="请输入账户"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="密码"
                  rules={[{ required: true, message: '请输入密码!' }]}>
                  <Input.Password
                    maxLength={30}
                    className="input"
                    placeholder="密码"
                  />
                </Form.Item>
                <Row>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <span
                      className="remember-pass"
                      style={{ color: '#607091', fontSize: '14px' }}>
                      <Checkbox
                        className="remember-check"
                        onChange={(e) => {
                          toggleRememberPwd(e.target.checked)
                        }}
                        checked={rememberPwd}>
                        记住密码
                      </Checkbox>
                    </span>
                  </Col>
                </Row>

                <Button
                  loading={loading}
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={loading}>
                  登录
                </Button>
              </Form>
            </div>
          </div>
        )}
        {page === PAGE_TYPE.ABOUT_US && <AboutUs />}
        {page === PAGE_TYPE.PRODUCT_INTRO && <ProductIntro />}
      </div>
    </div>
  )
}

export default observer(Login)
