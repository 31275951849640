import React from 'react'

const Platform = () => {
  return (
    <div className="platform">
      <div className="top">
        <img
          className="top-img"
          src={require('../../../assets/img/aboutUs/platform_3.png')}
          alt="platform"
        />
        <img
          className="middle-img"
          src={require('../../../assets/img/aboutUs/platform_1.png')}
          alt="platform"
        />
        <div className="middle">
          <img
            src={require('../../../assets/img/aboutUs/platform_2.png')}
            alt="platform"
          />
          <div className="our-platform-title">我们的平台</div>
        </div>
      </div>
      <div className="content">
        <p>
          乂保是一家由浦东软件园孵化并成立的科技型企业，坚持以互联网科技追求“保险数字化”，通过打造保险服务新模式，实现更便捷、更高效、更智能的保险服务体验。
        </p>
        <br />
        <p>
          乂保团队主要成员来自于保险、检验、法律等多个行业，具有丰富的行业经验和专业技能，乂保团队将始终聚焦客户需求，以协助控风险、促发展为目标，深耕细作，笃行致远，为所有客户的企业经营保驾护航。
        </p>
      </div>
    </div>
  )
}

export default Platform
