import { makeAutoObservable, runInAction } from 'mobx'
import * as API from 'service/api/InsureCorpManageApi'
import table from '../common/table'
import { IInit } from 'models/Global.model'
import { IInsureCorp } from 'models/InsureCorpManage.model'

class InsureCorpManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  insureCorpTable = new table(API.GET_INSURE_CORP_PAGE_LIST)

  init = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.insureCorpTable.init(param, isPageNum, isResetParam)
    this.insureCorpTable.getList(pageNo, pageSize)
  }

  addInsureCorp = async (data: IInsureCorp) => {
    return await API.ADD_INSURE_CORP(data)
  }

  getInsureCorpInfo = async (data: IInsureCorp) => {
    return await API.GET_INSURE_CORP_INFO(data)
  }
  updateInsureCorp = async (data: IInsureCorp) => {
    return await API.UPDATE_INSURE_CORP(data)
  }

  delInsureCorp = async (id: string) => {
    return await API.DEL_INSURE_CORP(id)
  }

  disabledStatus = async (data: IInsureCorp) => {
    return await API.DISABLE_INSURE_CORP(data)
  }

  enabledStatus = async (data: IInsureCorp) => {
    return await API.ENABLE_INSURE_CORP(data)
  }

  insureCorpList: IInsureCorp[] = []
  getInsureCorpList = async (data: any) => {
    let res = await API.GET_INSURE_CORP_LIST(data)
    runInAction(() => {
      if (res.code === 200) {
        this.insureCorpList = res.data || []
      } else {
        this.insureCorpList = []
      }
    })
  }

  insureSubCorpTable = new table(API.GET_INSURE_SUB_CORP_PAGE_LIST)

  initInsureSubCorp = (data: IInit) => {
    const {
      param = {},
      pageSize = 10,
      pageNo = 1,
      isPageNum = true,
      isResetParam = false
    } = data

    this.insureSubCorpTable.init(param, isPageNum, isResetParam)
    this.insureSubCorpTable.getList(pageNo, pageSize)
  }

  addInsureSubCorp = async (data: IInsureCorp) => {
    return await API.ADD_INSURE_SUB_CORP(data)
  }

  getInsureSubCorpInfo = async (data: IInsureCorp) => {
    return await API.GET_INSURE_SUB_CORP_INFO(data)
  }
  updateInsureSubCorp = async (data: IInsureCorp) => {
    return await API.UPDATE_INSURE_SUB_CORP(data)
  }

  delInsureSubCorp = async (id: string) => {
    return await API.DEL_INSURE_SUB_CORP(id)
  }

  disabledInsureSubCorp = async (data: IInsureCorp) => {
    return await API.DISABLE_INSURE_SUB_CORP(data)
  }

  enabledInsureSubCorp = async (data: IInsureCorp) => {
    return await API.ENABLE_INSURE_SUB_CORP(data)
  }
}

export default new InsureCorpManageStore()
