import { makeAutoObservable, runInAction } from 'mobx'
import { IInsureCorp } from 'models/InsureCorpManage.model'
import { ICountry } from 'models/Insure.model'
import {
  CARGO_TYPE,
  INSURE_TYPE,
  IProductItem,
  TRANSPORT_MODE,
  TRANSPORT_TOOL
} from 'models/ProductManage.model'
import { IExtraRisk, IMainRisk } from 'models/RiskManage.model'
import * as API from 'service/api/InsureManageApi'

class InsureManageStore {
  constructor() {
    makeAutoObservable(this)
  }

  submitInsure = async (data: any) => {
    return await API.SUBMIT_INSURE(data)
  }

  saveInsure = async (data: any) => {
    return await API.SAVE_INSURE(data)
  }

  countryList: ICountry[] = []
  getCountryList = async () => {
    let res = await API.GET_COUNTRY_LIST()
    runInAction(() => {
      if (res.code === 200) {
        this.countryList = res.data || []
      } else {
        this.countryList = []
      }
    })
  }

  currencyList: ICountry[] = []
  getCurrencyList = async () => {
    let res = await API.GET_CURRENCY_LIST()
    runInAction(() => {
      if (res.code === 200) {
        this.currencyList = res.data || []
      } else {
        this.currencyList = []
      }
    })
  }

  uploadInsureFile = async (data: any) => {
    return await API.UPLOAD_INSURE_INFO(data)
  }

  cargoTypeList: CARGO_TYPE[] = []
  insurCropList: IInsureCorp[] = []
  insureTypeList: INSURE_TYPE[] = []
  transportModeList: TRANSPORT_MODE[] = []
  transportToolList: TRANSPORT_TOOL[] = []
  productItem: IProductItem | undefined
  getInsureProductItemList = async () => {
    let res = await API.GET_INSURE_PRODUCT_ITEM_LIST()
    runInAction(() => {
      if (res.code === 200) {
        // let {
        //   cargoTypeList = [],
        //   insuCropList = [],
        //   insureTypeList = [],
        //   transportModeList = [],
        //   transportToolList = []
        // } = res.data || {}
        // this.cargoTypeList = cargoTypeList
        // this.insurCropList = insuCropList
        // this.insureTypeList = insureTypeList
        // this.transportModeList = transportModeList
        // this.transportToolList = transportToolList
        this.productItem = res.data || {}
      }
    })
  }

  matchInsureProduct = async (data: any) => {
    return await API.MATCH_INSURE_PRODUCT(data)
  }

  downloadPreviewFile = async (data: any) => {
    return await API.DOWNLOAD_PREVIEW_FILE(data)
  }

  mainRiskList: IMainRisk[] = []
  getMainRiskList = async (data: any) => {
    let res = await API.GET_MAIN_RISK_LIST(data)
    runInAction(() => {
      if (res.code === 200) {
        this.mainRiskList = res.data || []
      } else {
        this.mainRiskList = []
      }
    })
  }

  extraRiskList: IExtraRisk[] = []
  getExtraRiskList = async (data: any) => {
    let res = await API.GET_EXTRA_RISK_LIST(data)
    runInAction(() => {
      if (res.code === 200) {
        this.extraRiskList = res.data || []
      } else {
        this.extraRiskList = []
      }
    })
  }

  getLetterLink = async () => {
    return await API.GET_LETTER_LINK()
  }
}

export default new InsureManageStore()
