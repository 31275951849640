import { downLoadFile, get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_MY_POLICY_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/my-policy/page-list`, data)
}

export const GET_MY_POLICY_INFO = (data: any) => {
  return post(`/${URL_PREFIX}/my-policy/getInfo`, data)
}

export const DEL_MY_POLICY = (data: any) => {
  return postFormData(`/${URL_PREFIX}/my-policy/del-insure-info`, data)
}

export const EXPORT_MY_POLICY = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/my-policy/list-export`, data)
}

export const DOWNLOAD_E_POLICY = (data: any) => {
  return downLoadFile(`/${URL_PREFIX}/my-policy/epolicy-dl`, data, 'post')
}
