import { mainInsuranceList, extraRiskList } from 'mock/insuranceList'
import { get, post, postFormData } from 'service/xhr/fetch'
import { URL_PREFIX } from 'utils/constant'

export const GET_MAIN_RISK_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/main-risk/page-list`, data)
}

export const GET_MAIN_RISK_INFO = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/main-risk/getInfo`, data)
}

export const ADD_MAIN_RISK = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/main-risk/input`, data)
}

export const UPDATE_MAIN_RISK = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/main-risk/update`, data)
}

export const DEL_MAIN_RISK = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/main-risk/del`, data)
}

export const GET_EXTRA_RISK_PAGE_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/extra-risk/page-list`, data)
}

export const ADD_EXTRA_RISK = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/extra-risk/input`, data)
}

export const UPDATE_EXTRA_RISK = (data: any) => {
  return post(`/${URL_PREFIX}/insure-set/extra-risk/update`, data)
}

export const DEL_EXTRA_RISK = (data: any) => {
  return postFormData(`/${URL_PREFIX}/insure-set/extra-risk/del`, data)
}

export const GET_EXTRA_RISK_LIST = (data: any) => {
  return get(`/${URL_PREFIX}/insure-set/extra-risk/list`, data)
}
