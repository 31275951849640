import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Layout } from 'antd'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LeftMenu from './LeftMenu'
import Header from './Header'
import { IRoute, getRoutes } from '../router'
import useStores from 'utils/store'
import { CLIENT_TYPE_KEY, USER_MENU_LIST_KEY } from 'utils/constant'
import NotFound from 'pages/NotFound'
import NoAuth from 'pages/NoAuth'
import { CLIENT_TYPE, getToken } from 'models/Global.model'
import './index.less'

const { Content } = Layout

const BasicLayout = () => {
  const { LoginStore } = useStores()
  let { clientType, authMenuList } = LoginStore

  let navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  clientType = clientType || localStorage.getItem(CLIENT_TYPE_KEY)
  const isAdmin = clientType === CLIENT_TYPE.ADMIN
  let token = getToken()

  authMenuList =
    authMenuList.length > 0
      ? authMenuList
      : JSON.parse(localStorage.getItem(USER_MENU_LIST_KEY) ?? '[]')
  let { allRoutes: routes, menus } = getRoutes(clientType, authMenuList)

  useEffect(() => {
    if (token) {
      if (routes.length > 0) {
        if (pathname === '/') {
          // 没有匹配到路由，跳转到默认路由
          let firstRoute = routes[0].path
          navigate(firstRoute)
        }
      }
    } else {
      navigate('/login')
    }
  }, [pathname, routes])
  return (
    <Layout className="basic-layout">
      <Header />
      <Layout>
        <LeftMenu menus={menus} />
        <Layout className="main-layout">
          <Content
            style={{
              padding: 15,
              margin: 0
            }}
            className="site-layout-content">
            {routes.length > 0 && (
              <Routes>
                {routes.map((route: IRoute) => (
                  <Route
                    path={`${route.path}`}
                    element={route.element}
                    key={route.key}
                  />
                ))}
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {routes.length === 0 && <NoAuth />}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default observer(BasicLayout)
