import { isCustom } from 'models/Global.model'
import {
  CLIENT_TYPE_KEY,
  PWD_KEY,
  REMEMBER_KEY,
  USER_KEY,
  ADMIN_REMEMBER_KEY,
  ADMIN_PWD_KEY,
  ADMIN_USER_KEY
} from './constant'

export const beforeLogout = () => {
  let NOT_DEL_KEYS = [
    CLIENT_TYPE_KEY,
    USER_KEY,
    PWD_KEY,
    REMEMBER_KEY,
    ADMIN_REMEMBER_KEY,
    ADMIN_PWD_KEY,
    ADMIN_USER_KEY
  ]
  let len = localStorage.length
  for (let i = len; i >= 0; i--) {
    // 获取key 索引从len开始
    let key = localStorage.key(i) ?? ''
    if (!NOT_DEL_KEYS.includes(key)) {
      localStorage.removeItem(key)
    }
  }
  // sessionStorage.clear()
  window.location.href = isCustom() ? '/login' : '/adminLogin'
}

export default beforeLogout
